import React from 'react';
import './footer.css';

export default function Footer() {
  return (
    <div className='footer'>
        <div className="dot"></div>
        <p className='made'>made by </p>
        <p className='atomcollab'>atom collab</p>
        <p className='o'>o</p>
        <p className='r'>r</p>
        <p className='a'>a</p>
        <p className='t'>t</p>
        <p className='i'>i</p>
        <p className='o2'>o</p>
        <p className='n'>n</p> 
        <div>
          <p className='footer-text'>Ai GUIDE</p>
        </div>
    </div>
  )
}
    

