import React, { useState } from 'react';
import './aiTools.css';
import 'animate.css';

export default function AiTools() {

    /*const [showQuestionWell, setShowQuestionWell] = useState(false);
    const [showGammaApp, setGammaApp] = useState(false);
    const [showGoblinTools, setGoblinTools] = useState(false);*/
    const [showBoxes] = useState(true);
  
    const handleHover = (event) => {
      const box = event.target;
      box.classList.add('animate__pulse');
    };
  
    const handleHoverOut = (event) => {
      const box = event.target;
      box.classList.remove('animate__pulse');
    };

  //   const handleMobileTools = (event) => {
  //     const parentBox = event.currentTarget.closest('.clickBox');
  //     const hiddenElements = parentBox.querySelectorAll('.hide');
  //     const showedElements = parentBox.querySelectorAll('.show');
  
  //     // For elements currently hidden, remove 'hide' and add 'show'
  //     hiddenElements.forEach(element => {
  //         element.classList.remove('hide');
  //         element.classList.add('show');
  //     });
  
  //     // For elements currently shown, remove 'show' and add 'hide'
  //     showedElements.forEach(element => {
  //         element.classList.remove('show');
  //         element.classList.add('hide');
  //     });
  // };

    /*const handleGammaAppClick = () => {
      setGammaApp(true);
      setShowBoxes(false);
    };
  
    const handleQuestionWellClick = () => {
      setShowQuestionWell(true);
      setShowBoxes(false);
    };

    const handleGoblinToolsClick = () => {
      setGoblinTools(true);
      setShowBoxes(false);
    };*/


  return (
    <>
    {showBoxes && (
    <div className='question-test'>
        <div className="boxesTitle">
          <h1 className='box'>AI VERKTYG </h1>
          <h3>Vertygen vi rekommenderar.</h3>
          <p>Här hittar du några riktigt bra ai-vertyg att använda dig av utöver Chat GPT. De effektiviserar och underlättar flera olika aspekter av ditt arbete. 
             Om du är helt ny med AI och vill veta mer om hur dessa vertyg används, kan du gå den <a className='link-rise' href="https://rise.articulate.com/author/dw5o_o3iFNLDmrKgiRQZrwkdTPH3FX-x#/author/course/preview"> HÄR</a> kursen där de fyra första vertygen förklaras mer ingående. 
          </p>
        </div>
        <div className='clickBoxes'>
            <div onMouseEnter={handleHover} onMouseLeave={handleHoverOut} className="clickBox questionWell-box animate__animated">
                <div className='titleImg boxTitleHide show'></div>
                <h1 className='boxTitleHide show'>Question Well <br /><span className='underText'>Genererar frågor</span></h1>
                <p className='boxTextHide hide'>Questionwell är ett AI-verktyg som snabbt genererar frågor från texter som PDF-filer eller artiklar, perfekt för att skapa quiz och liknande. Det sorteras i olika format och kategorier för enkel användning.</p>
                <a className='goToPage' href="https://www.questionwell.org/"><button className='readMoreBtn hide'>Gå till hemsida</button></a>
            </div>
            <div onMouseEnter={handleHover} onMouseLeave={handleHoverOut} className="clickBox gammaApp-box animate__animated">
                <div className='titleImgGamma boxTitleHide show'></div>
                <h1 className='boxTitleHide show'>Gamma App <br /><span className='underText'>power point generator</span></h1>
                <p className='boxTextHide hide'>Gamma är ett verktyg som snabbt skapar presentationer, vilka sedan enkelt kan exporteras som PDF, vilket sparar tid och arbete.</p>
                <a className='goToPage' href="https://gamma.app/?lng=en"><button className='readMoreBtn hide'>Gå till hemsida</button></a>
            </div>
            <div onMouseEnter={handleHover} onMouseLeave={handleHoverOut} className="clickBox goblinTools-box animate__animated">
                <div className='titleImgGoblin boxTitleHide show'></div>
                <h1 className='boxTitleHide show'>Goblin Tools <br /><span className='underText'>Todo-list generator</span></h1>
                <p className='boxTextHide hide'>Goblin Tools hjälper elever att strukturera och dela upp uppgifter i steg utan behov av konto eller inloggning. Elever kan markera färdiga uppgifter, exportera till kalendrar och få tidsuppskattningar. Ett effektivt och användarvänligt verktyg.</p>
                <a className='goToPage' href="https://goblin.tools/"><button className='readMoreBtn hide'>Gå till hemsida</button></a>
            </div>
            <div onMouseEnter={handleHover} onMouseLeave={handleHoverOut} className="clickBox goblinTools-box animate__animated">
                <div className='titleImgGoblin boxTitleHide show'></div>
                <h1 className='boxTitleHide show'>Pictory<br /><span className='underText'>Video generator</span></h1>
                <p className='boxTextHide hide'>Pictory är ett verktyg som omvandlar textbaserat läromaterial till videor genom att man kopierar in en URL. Det förenklar även skapandet av videos för marknadsföring eller Youtube-kanaler.</p>
                <a className='goToPage' href="https://pictory.ai/"><button className='readMoreBtn hide'>Gå till hemsida</button></a>
            </div>
        </div>
        <div className='clickBoxes'>
            <div onMouseEnter={handleHover} onMouseLeave={handleHoverOut} className="clickBox questionWell-box animate__animated">
                <div className='titleImg boxTitleHide show'></div>
                <h1 className='boxTitleHide show'>Perplexity <br /><span className='underText'>AI-driven informationssökning</span></h1>
                <p className='boxTextHide hide'>Detta AI-verktyg skiljer sig från traditionella sökmotorer genom att fokusera på att tolka sammanhanget i användarnas frågor och erbjuda svar som är direkt relevanta, vilket gör informationsinhämtningen mer effektiv och användarvänlig.</p>
                <a className='goToPage' href="https://www.perplexity.ai/"><button className='readMoreBtn hide'>Gå till hemsida</button></a>
            </div>
            <div onMouseEnter={handleHover} onMouseLeave={handleHoverOut} className="clickBox gammaApp-box animate__animated">
                <div className='titleImgGamma boxTitleHide show'></div>
                <h1 className='boxTitleHide show'>Elicit<br /><span className='underText'>AI-förstärkt forskningsanalys</span></h1>
                <p className='boxTextHide hide'>Elicit är en AI-baserad forskningsassistent utformad för att hjälpa användare att utföra omfattande litteraturgranskningar snabbare än traditionella metoder. </p>
                <a className='goToPage' href="https://elicit.com/"><button className='readMoreBtn hide'>Gå till hemsida</button></a>
            </div>
            <div onMouseEnter={handleHover} onMouseLeave={handleHoverOut} className="clickBox questionWell-box animate__animated hide-box">
                <div className='titleImg boxTitleHide show'></div>
                <h1 className='boxTitleHide hide'>Perplexity <br /><span className='underText'>AI-driven informationssökning</span></h1>
                <p className='boxTextHide hide'>Detta AI-verktyg skiljer sig från traditionella sökmotorer genom att fokusera på att tolka sammanhanget i användarnas frågor och erbjuda svar som är direkt relevanta, vilket gör informationsinhämtningen mer effektiv och användarvänlig.</p>
                <a className='goToPage' href="https://www.perplexity.ai/"><button className='readMoreBtn hide'>Gå till hemsida</button></a>
            </div>
            <div onMouseEnter={handleHover} onMouseLeave={handleHoverOut} className="clickBox gammaApp-box animate__animated hide-box">
                <div className='titleImgGamma boxTitleHide show'></div>
                <h1 className='boxTitleHide hide'>Elicit<br /><span className='underText'>AI-förstärkt forskningsanalys</span></h1>
                <p className='boxTextHide hide'>Elicit är en AI-baserad forskningsassistent utformad för att hjälpa användare att utföra omfattande litteraturgranskningar snabbare än traditionella metoder. </p>
                <a className='goToPage' href="https://elicit.com/"><button className='readMoreBtn hide'>Gå till hemsida</button></a>
            </div>
        </div> 
    </div>
    )}
     {/*
      {showGoblinTools && <GoblinTools/>}
     {showGammaApp && <GammaApp/>}
     {showQuestionWell && <QuestionWell/>}
      */}
     </>
  )
}
