import React from 'react';
import './heads.css';
import textPic from '../../Images/".png';

export default function Heads() {
  return (
    <div className='heads'>
        <div className="heads-left">
            <div className="headsCitat">
                <h1>Podd</h1>
                <p>Från färdigheter till framtid: AI:s påverkan på kompetensutveckling</p>
                <button className='articleBtnOne'><a href="https://open.spotify.com/episode/1MqRWp5qjXaMdUvYeScDPJ?go=1&sp_cid=2f10d9dfaf23d1eb9d49a3ec2ef596cb&utm_source=embed_player_p&utm_medium=desktop&nd=1&dlsi=81be70abc8094b3f">Gå till podd</a></button>
            </div>
            <div className="headsCitat">
                <h1>Artikel</h1>
                <p> <strong>2024 Global Human Capital Trends</strong> <br /></p>
                <p>What will it take for organizations—and humans—to thrive in a boundaryless world? </p>
                <button className='articleBtnTwo'><a href="https://www2.deloitte.com/us/en/insights/focus/human-capital-trends.html">Gå till artikel</a></button>
            </div>
        </div>
        <div className="heads-right">
            <div className="headsCitat">
                <img src={textPic} alt="" />
                <p>Artificiell intelligens är inte bara en ny teknik, det är en ny era av mänskligheten.</p>
                <img className='fjompar' src={textPic} alt="" />
            </div>
            <div className="headsCitat">
            <h1>Mer av Atom</h1>
                <p>Läs fler av Atoms artiklar och mycket mer på hemsidan</p>
                <button className='articleBtnThree'><a href="https://atomcollaboration.se/sv/artiklar/">Gå till hemsidan</a></button>
            </div>
        </div>
    </div>
  )
}
