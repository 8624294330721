import React from 'react';
import './sectionOne.css';

export default function SectionOne() {
  return (
    <div className='section-one'>
        <div className='section-div'>
            <h3 className='section-title'> Från djupinlärning till naturlig språkbearbetning har vi bara skrapat på ytan av dess potential.</h3>
            <p className='section-text'> 
              Men en sak är säker, AI:s resa är långt ifrån över. Detta är inte ett slut, 
              det är början på en ny era där teknik och kreativitet sammanflätas för att 
              forma vår framtid. Så, oavsett om du är en drömmare, en tänkare, en skapare, 
              eller en visionär, kom ihåg att i AI:s värld är endast fantasin gränsen. 
              Sida vid sida med AI är möjligheterna oändliga. 
            </p>
        </div>
    </div>
  )
}
