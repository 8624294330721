import React, { useState } from 'react';
import './promptEngineering.css';
import 'animate.css';

export default function PromptEngineering() {
  const [visibleBubbles, setVisibleBubbles] = useState([0]);
  const [currentConversation, setCurrentConversation] = useState(0);

  const nextBubble = () => {
    setVisibleBubbles((prevBubbles) => {
      const nextIndex = Math.min(prevBubbles[prevBubbles.length - 1] + 1, totalBubbles - 1);
      return [...prevBubbles, nextIndex];
    });
  };

  const prevBubble = () => {
    setVisibleBubbles((prevBubbles) => {
      const lastIndex = prevBubbles[prevBubbles.length - 1];
      return prevBubbles.length > 1 ? prevBubbles.slice(0, -1) : [lastIndex];
    });
  };

  const nextConversation = () => {
    setCurrentConversation((prevConversation) => prevConversation + 1);
    setVisibleBubbles([0]);
  };

  const prevConversation = () => {
    setCurrentConversation((prevConversation) => Math.max(prevConversation - 1, 0));
    setVisibleBubbles([0]);
  };

  const totalBubbles = 4; 
  const conversations = [
    [ 
      "Vad tycker du om att AI tar bort jobb? Är det bra eller dåligt?",
      "Denna prompt är alltför bred och saknar specificitet. Den antyder också ett binärt svar ('bra eller dåligt'), vilket inte fångar komplexiteten i frågan.",
      "Kan du ge en analys av hur artificiell intelligens har påverkat sysselsättningen inom tillverkningssektorn under det senaste decenniet? Vänligen inkludera statistik över jobbförluster och vinster, samt potentiella strategier för att mildra negativa effekter på uppsagda arbetare.",
      "Denna fråga är mer specifik och ger tydliga riktlinjer för den önskade informationen. Den uppmuntrar till en djupgående utforskning av ämnet och inbjuder till ett omfattande svar."
    ],
    [
      "Vad är din åsikt om klimatförändringarna? Är det något vi bör oroa oss för?",
      "Denna prompt är alltför generell och saknar tydlig riktning. Den ger inte tillräckligt med kontext för att möjliggöra en meningsfull diskussion.",
      "Kan du presentera en översikt över de viktigaste drivkrafterna bakom klimatförändringarna och deras påverkan på globala ekosystem och samhällen? Dessutom, vilka åtgärder bör prioriteras för att minska utsläpp och anpassa sig till de redan pågående förändringarna?",
      "Denna fråga är mer specifik och uppmanar till en djupgående diskussion om orsakerna till och konsekvenserna av klimatförändringar. Den inbjuder också till att utforska åtgärder för att adressera problemet på ett meningsfullt sätt."
    ],
    [
      "Vad tycker du om förnybar energi? Är det värt investeringen?",
      "Denna prompt är ganska generell och ger inte tillräckligt med kontext för en djupgående diskussion om ämnet. Den tenderar att söka efter en subjektiv åsikt istället för en mer informativ analys.",
      "Kan du ge en jämförelse av kostnadseffektiviteten och miljöpåverkan mellan förnybar energi, som sol- och vindkraft, och konventionella energikällor som kol och olja? Dessutom, vilka politiska åtgärder eller ekonomiska incitament kan främja övergången till förnybar energi på global nivå?",
      "Denna fråga är mer specificerad och ger en tydlig riktning för diskussionen. Den uppmuntrar till en analys av både ekonomiska och miljömässiga aspekter av olika energikällor samt betonar betydelsen av politiska åtgärder för att främja hållbar energiutveckling."
    ],
  ];

  return (
    <div>
      <h1 className='prompts-big-title'>MER OM PROMPTS</h1>
      <div className='section-three-left'>
        <p className='leftTitle'>Vad är Promt Engeneering? </p>
        <p className='leftText'>Prompt engineering är ett relativt nytt, och mycket essentiellt begrepp när det kommer till framtiden med Large Language Models (LLM, en kategori inom AI).  
        Prompt engeneering är en metod där du formulerar dina prompts på ett särskilt sätt för att få ut ett så optimalit svar som möjligt från AI:n. <br /> <br />
        Att få ut bättre information från AI-modellen innebär att din digitala assistent kan utföra sina arbetsuppgifter bättre, som att t.ex. skriva mejl, nyhetsbrev, inlägg för sociala medier, generera kodstycken, analysera och sammanfatta längre texter, ta fram digitala bilder och musikstycken, interagera med kunder som en chatbot mm. </p>
        <p className='leftText'>Tryck på pilarna för att se olika promptexempel till ChatGPT</p>
      </div>
      <div className='prompts-div'>
  <div className='section-three'>
    <div className='section-three-right'>
      {visibleBubbles.map((bubbleIndex, index) => (
        <div key={index} className={`animate__animated animate__lightSpeedInRight chat-bubble-${index % 2 === 0 ? 'you animate__animated animate__lightSpeedInLeft' : 'me'}`}>
          <p><strong>{index % 2 === 0 ? 'Prompt:' : 'Reflektion:'}</strong> {conversations[currentConversation][bubbleIndex]}</p>
        </div>
      ))}
    </div>
    <div>
      <button className='clickLeft' onClick={prevBubble} disabled={visibleBubbles.length === 1}>◄</button>
      <button className='clickRight' onClick={nextBubble} disabled={visibleBubbles.length === totalBubbles}>►</button>
    </div>
    {/* Endast visa nästa och föregående konversationsknappar om alla bubblor har visats */}
    {visibleBubbles.length === totalBubbles && (
      <div className="arrow-buttons">
        <button className='prevConv' onClick={prevConversation} disabled={currentConversation === 0}>Föregående exempel</button>
        <button className='nextConv' onClick={nextConversation} disabled={currentConversation === conversations.length - 1}>Nästa exempel</button>
      </div>
    )}
  </div>
</div>
    </div>
  );
}

